<template>
  <div
    class="aaaaa"
    :style="{
      overflow: 'hidden',
      position: 'relative',
    }"
  >
    <a-drawer
      :placement="placement"
      :closable="false"
      :visible="visible"
      class="map-drawer"
      width="569"
      @close="onClose"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
    >
    </a-drawer>
    <a-button @click="onClick" type="primary"> Primary </a-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      placement: "left",
    };
  },

  methods: {
    onClick() {
      this.visible = true;
      console.log(this.visible);
    },

    onClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#aaaaa {
  height: 100%;
}
</style>
